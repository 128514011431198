export const colors = {
  white: '#fff9f0',
  //pink: '#fccde5',
  pink: '#f7abd1',
  black: 'rgb(4,4,4)',
  lightBlue: '#bfddff',
}

export const variables = {
  siteWidthDesktop: '70%',
  breakPoint: 850,
  menuBreakPoint: 1100,
  HPaddingMobile: 25,
  HPaddingDesktop: 100,
  desktop: '@media (min-width: 850px)',
  mediumScreen: '@media (max-width: 1370px)',
  mobile: '@media (max-width: 849px)',
}

export const globalCss = `

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');

body {
  font-family: 'PT Sans', sans-serif;
  background-color: ${colors.black};
  user-select: none;
  scroll-behaviour: auto;
}

.container {
  overflow: hidden;
}

a {
  color: inherit; 
  text-decoration: inherit; 
}

a:visited{
  text-decoration: none;
  color: inherit; 

}

footer a{
  transition: all 0.3s
}


.navLinkMobile {
  text-decoration: none;
  margin-left: 20px;
  font-size: 2rem;
  margin: 20px 20px;
  position: relative;
}

.navLink {
  position: relative;
  margin-right: 5%;
}

.navLink:last-child{
  margin-right: 5%;
}

.navLink:first-of-type{
  margin-left: auto;
}

.navLink:hover .menuLine{
  width: 100%;
}

.hoverScale{
  transition: transform 0.3s;
  transform-origin: center;
}

.hoverScale:hover {
  transform: scale(1.05);
}

.menuLine {
  background-color: ${colors.white}aa;
  background: linear-gradient(180deg, ${colors.white}00 0%, ${colors.white}ff 100%);
  width: 0px;
  height: 3px;
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.4s;
}

.menuLineMobile {
  background-color: ${colors.white}aa;
  background: linear-gradient(180deg, ${colors.white}00 0%, ${colors.white}ff 100%);
  width: 90%;
  height: 1px;
  position: absolute;
  bottom: 3px;
  left: 0px;
  transition: width 0.4s;
}

.show{ opacity: 1;}
.hide{opacity: 0;}

.up{ top: -60px;}
.down{top: 0px;}

.react-icons {
  vertical-align: middle;
}

p, button{
  font-size: 1.1rem;
  letter-spacing: 0.09rem;
  line-height: 1.6rem;
}

h1, h2, h3, h4{ 
  font-family: 'Oswald';
  text-shadow: 2px 2px ${colors.lightBlue}, -2px 2px ${colors.lightBlue}, 2px -2px ${colors.lightBlue}, -2px -2px ${colors.lightBlue}, 0px 0px 10px ${colors.lightBlue};
  text-transform: uppercase;
  letter-spacing: 0.18rem;
  color: ${colors.black};
  font-weight: 700;
}

h2 {
  font-size: 3rem;
}

h4{
  font-size: 2rem;
  text-shadow: 1px 1px ${colors.pink}, -1px 1px ${colors.pink}, 1px -1px ${colors.pink}, -1px -1px ${colors.pink}, 0px 0px 6px ${colors.pink};
  margin-bottom: 1rem;
}

@media (max-width: 849px){
  p, button{
    font-size: 0.9rem;
    letter-spacing: 0.09rem;
    line-height: 1.4rem;
  }

  h2 {
    font-size: 2.1rem;
  }

  h4{
    font-size: 1.5rem;
    text-shadow: 1px 1px ${colors.pink}, -1px 1px ${colors.pink}, 1px -1px ${colors.pink}, -1px -1px ${colors.pink}, 0px 0px 6px ${colors.pink};
    margin-bottom: 1rem;
  }
}



@keyframes spin{
  from{transform: rotateY(0deg)}

  to{transform: rotateY(360deg)}
}

@keyframes fancyAppear{
  0%{
    transform: scale(1);
  }

  20% {
    transform: scale(1.1);
  }

  40%{
    transform: scale(1);
  }

  100%{
    transform: scale(1);
  }
}

@keyframes draai{
  0%{
    transform: rotateX(0deg);
  }
  
  100%{
    transform: rotateX(360deg);
  }
}

.formDiv{
  margin-bottom: 2rem;
  height: 70px;
}

.inputLabel{
  display: block;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

input, textarea, .submitButton {
  appearance: none;
  background-color: inherit;
  border: 1px solid ${colors.white};
  border-radius: 4px;
  color: ${colors.white};
  font-size: 1.2rem;
  padding: 8px;
  box-shadow: 0px 0px 0px ${colors.white};
  transition: box-shadow 0.5s;
}

select {
  background-color: ${colors.black};
  color: ${colors.white};
  font-size: 1rem;

}

.submitButton {
  padding: 15px;
  border-radius: 10px;
  transition: color, background-color 0.5s;
}

.submitButton:hover{
  color: ${colors.black};
  background-color: ${colors.white};
}

textarea {
  width: 60%;
  margin-bottom: 2rem;


}

input:focus, textarea:focus{
  box-shadow: 0px 0px 10px ${colors.white};
  border: 1px solid ${colors.white};
  outline: none;
}

.inputKlein{
  width: 40%;
  min-width: 300px;
}

select {
  height: 25px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

${variables.mobile} {


  .inputKlein{
    width: 90%;
    min-width: 100px;
  }
  textarea {
    width: 90%;
  }
    
}

`
