/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import React, { useContext, useState, useEffect } from 'react'
import useStore from '../store'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { colors } from '../styles/global'

export default function CookieConsentPopup() {
  const isShowingCookiePopup = useStore((state) => state.isShowingCookiePopup)
  const setIsShowingCookiePopup = useStore(
    (state) => state.setIsShowingCookiePopup
  )
  const location = useLocation()

  useEffect(() => {
    if (
      !getCookie('hasAskedCookieConsent') &&
      getCookie('gatsby-plugin-google-analytics-gdpr_cookies-enabled') !==
        'true'
    ) {
      setIsShowingCookiePopup(true)
    }

    return () => {}
  }, [])

  function onAccept() {
    if (typeof window !== 'undefined') {
      setCookie('gatsby-gdpr-google-analytics', true, 365)
      setCookie('hasAskedCookieConsent', true, 365)
      setIsShowingCookiePopup(false)
      initializeAndTrack(location)
    }
  }

  function onReject() {
    if (typeof window !== 'undefined') {
      setCookie('gatsby-gdpr-google-analytics', false, 10)
      setCookie('hasAskedCookieConsent', true, 10)
      setIsShowingCookiePopup(false)

      initializeAndTrack(location)
    }
  }

  return isShowingCookiePopup ? (
    <div
      style={{
        backgroundColor: colors.black,
        zIndex: 999999,
        borderTop: `1px solid ${colors.pink}`,
        width: '100vw',
        position: 'fixed',
        left: 0,
        bottom: 0,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '32px 0px',
      }}
    >
      <div
        style={{
          maxWidth: 'min(600px, 100vw)',
          minWidth: '320px',
          padding: '0px 16px',
          boxSizing: 'border-box',
        }}
      >
        <h3 style={{ fontSize: '2.5rem', color: colors.black, margin: 0 }}>
          <b>Cookies</b>
        </h3>

        <p style={{ fontSize: '1.3rem', color: colors.white }}>
          We gebruiken cookies om het gebruik en de functionaliteit van onze
          website te analyseren en verbeteren.
        </p>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <button
            onClick={onAccept}
            style={{
              width: '130px',
              textAlign: 'center',
              padding: '8px 0',
              margin: '16px 20px 16px 0',
              backgroundColor: colors.pink,
              color: colors.black,
              cursor: 'pointer',
              outline: 'none',
              border: `1px solid ${colors.lightBlue}`,
              borderRadius: '4px',
            }}
          >
            Accepteren
          </button>
          <button
            onClick={onReject}
            style={{
              width: '130px',
              textAlign: 'center',
              padding: '8px 0',
              margin: '16px 0px 16px 0',
              opacity: 0.6,
              backgroundColor: colors.black,
              color: colors.lightBlue,
              cursor: 'pointer',
              outline: 'none',
              border: `1px solid ${colors.lightBlue}`,
              borderRadius: '4px',
            }}
          >
            Weigeren
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export function setCookie(cName: string, cValue: any, exDays: number) {
  if (typeof window !== 'undefined') {
    const d = new Date()
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + d.toUTCString()
    document.cookie = cName + '=' + cValue + ';' + expires + ';path=/'
  }
}

export function getCookie(cName: string) {
  if (typeof window !== 'undefined') {
    let name = cName + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
}
